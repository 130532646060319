import { 
  Container, 
  Title, 
  Grid,
  Image,
  Text,
  Button,
  Stack,
  Group
} from '@mantine/core';
import { useScrollIntoView } from '@mantine/hooks';
import LPFooter from '../modules/lp/LPFooter';
import { ContactUs } from '../modules/lp/ContactUs';
import { pickUrl } from '../lib/uris';
import { Link } from 'react-router-dom';
import { IconBrandInstagram, IconBrandLinkedin } from '@tabler/icons-react';

const url = pickUrl();

function Founder({img, name, ig, linkedin, urls}) {
  const linkStyle = {textDecoration: 'none', color: '#ff5a60'};
  return (
    <>
      <Image src={`${url}/img/lp/${img}`} alt={name} radius="md" imageProps={{style: {objectPosition: '0 0', objectFit: 'cover', width: '100%', height: '400px'}}} />
      <Stack align="center" justify="center" spacing="2" mt={10}>
        <Text fw={700}>{name}, Co-Founder</Text>
        <Group spacing="5">
          {ig && <Link style={linkStyle} to={`https://instagram.com/${ig}`} target="_blank"><IconBrandInstagram color="#FF5a60" size="2rem" stroke="1.5" /></Link>}
          {linkedin && 
          <Link style={linkStyle} to={`https://linkedin.com/in/${linkedin}`} target="_blank"><IconBrandLinkedin color="#FF5a60" size="2rem" stroke="1.5" /></Link>}
        </Group> 
        {urls.map(url => <Text key={url}><Link to={url} style={linkStyle} target="_blank">{url.replace('https://', '')}</Link></Text>)}
      </Stack>
    </>
  )
}

export default function OurStory() {
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60,
  });

  return (
    <div>
      <Container my={50}>
        <Grid grow>
          <Grid.Col span={12} md={6}>
            <Title mb="lg">Our Story</Title>
            <Text mb="lg">
              Plainstage was born, as all great ideas are, with margaritas and an extra shot of frustration. As artists-producers ourselves, we grew disheartened by how much money the bigger players in event ticketing were charging. So, we asked ourselves, &quot;How hard can it be to build a ticketing platform designed with the independent producer in mind?&quot; 
            </Text>
            <Text mb="lg">
              One of our founders knew tech and the answer was clear: anything is possible (famous last words, RIP Don). That's when we decided to create a platform designed for smaller events, simplifying the process and allowing artists to keep more of their hard-earned sales. In a nutshell, we built the ticketing platform we wished existed when we started.
            </Text>
            <Button onClick={() => scrollIntoView({alignment: 'center'})}>Contact Us</Button>
          </Grid.Col>
          <Grid.Col span={0} md={6}>
            <Image mx="auto" radius="md" src={`${url}/img/lp/sarah.jpg`} caption={<Text>This is Sarah. She likes sparkly things. It helps her fill the creative void.<br /><em>Photo credit: Joelle Wyminga</em></Text>} alt="Sarah Angelle" />
          </Grid.Col>
        </Grid>
        <Title order={1} mt={40} mb={10}>Our Goal</Title>
        <Text mb={20}>It's simple, really. We love art and we want to make it more accessible. We value your feedback and always welcome suggestions as we continue to build this platform with you in mind. Let's create together.</Text>
        <Image mx="auto" radius="md" src={`${url}/img/lp/don.jpg`} caption={<Text>This is Don. He doesn't sleep much. He likes too many projects and has a tendency to overcommit.<br /><em>Photo credit: Georgina Frias</em></Text>}  alt="Don likes coffee." />
        <Title order={1} mt={50} mb={10}>Get in touch</Title>
        <Text mb={25}>Whether it's ticketing or creative projects, let's connect. Check out our socials to see what else we're doing!</Text>
        <Grid grow>
          <Grid.Col span={12} md={6}>
            <Founder img='don-hs.jpg' name='Don Burks' ig='don_burks' urls={['https://donburks.com']} linkedin="donaldburks" />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <Founder img='sarah-hs.jpg' name='Sarah Angelle' ig='sarahangelle_' urls={['https://sarahangelle.com', 'https://lilixmedia.com']} />
          </Grid.Col>
        </Grid>
      </Container>
      <ContactUs ref={targetRef} />
      <LPFooter />
    </div>
  );
}